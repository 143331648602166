import React from "react";
import MainLayout from "../components/layoutmain";
import TitleBrushed from "../components/titlebrushed";
import LayoutCentered from "../components/layoutcentered";
import { graphql, useStaticQuery } from "gatsby";

export default function EcoconceptionWeb() {
  const { site } = useStaticQuery(query);
  const seoProps = {
    title: "Numeko - L'éco-conception web",
    description:
      "Qu'est que l'éco-conception appliquée aux services numériques ou éco-conception web - L'impact environnemental, social et économique du numérique - Bénéfices de l'éco-conception web",
  };

  return (
    <MainLayout seoProps={seoProps}>
      <div className={"bloc-main eco-conception-web"}>
        <div className={"bloc-category bloc-presentation"}>
          <h1>L'ÉCO-CONCEPTION WEB</h1>
          <hr />
          <h4>
            Chez <span>Numeko</span>, nous avons à coeur de nous différencier en
            mettant en place une approche basée sur l'<span>optimisation</span>{" "}
            et la <span>qualité</span> de nos applications, tout en intégrant
            les enjeux <span>environnementaux</span>, <span>sociaux</span> et{" "}
            <span>économiques</span>.
          </h4>
        </div>

        <div className={"bloc-category bloc-content citation"}>
          <div className={"content"}>
            <div className={"numeko-icon-quotes-left"} />
            Les études et expériences montrent que plus une application ou
            solution mobile est éco-conçue, donc moins énergivore, plus elle
            sera efficace et économe.
          </div>
        </div>

        <div className={"bloc-category bloc-content"}>
          <LayoutCentered size={"medium"}>
            <TitleBrushed
              color={"brown"}
              position={"right"}
              name={"L'éco-conception ?"}
            />
            <div className={"definition"}>
              <div className={"content"}>
                <p>
                  L’<span>éco-conception</span> web est une{" "}
                  <span>démarche</span> d'amélioration permettant de prendre en
                  compte les impacts <span>environnementaux</span> sur
                  l’ensemble du <span>cycle de vie</span> d’un projet numérique,
                  en s'efforçant de préserver ses qualités et performances.{" "}
                  <br />
                  En intégrant les dimensions <span>sociale</span> et{" "}
                  <span>économique</span>, elle mène à la{" "}
                  <span>conception numérique responsable</span>.
                </p>
                <p>
                  Contrairement à une démarche de performance, l'éco-conception
                  cherche l'<span>efficience</span> : en faire autant avec le
                  moins de ressources possibles, éviter le <span>surplus</span>{" "}
                  de fonctionnalités inutiles.
                </p>
                <p>
                  C'est une <span>discipline transversale</span>, où il est
                  nécessaire d’optimiser l’ensemble des éléments constitutifs
                  d’une application : <span>hébergement</span>,{" "}
                  <span>sobriété fonctionnelle</span>,{" "}
                  <span>bonnes pratiques</span> de <span>design</span> et de{" "}
                  <span>développement</span>, etc.
                  <br />
                  Elle agit sur 3 sources physiques : <span>
                    terminaux
                  </span>, <span>réseaux</span> et <span>datacenters</span>.
                </p>
              </div>
              <div className={"infographie"}>
                <h3>Leviers par étape du cycle de vie</h3>
                <ul>
                  <li>Conception fonctionnelle</li>
                  <li>Conception technique</li>
                  <li>Développement</li>
                  <li>Hébergement</li>
                </ul>
                <p>
                  Source : <a href={site.siteMetadata.urlGreenIT}>GreenIT.fr</a>
                </p>
              </div>
            </div>
          </LayoutCentered>
        </div>

        <div className={"bloc-category bloc-content some-numbers"}>
          <LayoutCentered size={"medium"}>
            <TitleBrushed
              color={"yellow"}
              textColor={"white"}
              position={"left"}
              name={"Pourquoi ?"}
            />
            <div>
              <div className={"content"}>
                Le <span>numérique</span> est un des secteurs où l'
                <span>évolution</span> des impacts environnementaux est la plus{" "}
                <span>forte</span>. Pour le moment, les bonnes initiatives sont
                encore <span>trop faibles</span> comparées à l'ampleur de sa
                pollution.
                <h2>Il faut donc agir, maintenant</h2>
                Par la mise en place de <span>bonnes pratiques</span>, dont l'
                <span>éco-conception</span> d'applications plus{" "}
                <span>légères</span> et <span>accessibles</span> à tous.
                <h4>L'impact du numérique en chiffres :</h4>
              </div>
              <div className={"bloc-number-container"}>
                <div className={"bloc-number-env"}>
                  <div className={"number"}>4%</div>
                  <div className={"number-description"}>
                    des émissions de gaz à effet de serre
                    <span>échelle mondiale*</span>
                  </div>
                  <hr />
                  <div className={"effect"}>
                    Le numérique émet plus de gaz à effet de serre que l’
                    <span>aviation civile</span>
                  </div>
                  <div className={"source"}>
                    Source :{" "}
                    <a href={site.siteMetadata.urlGreenIT}>GreenIT.fr</a>
                  </div>
                </div>
                <div className={"bloc-number-eco"}>
                  <div>
                    <div className={"number"}>25%</div>
                    <div className={"number-description"}>
                      des applications jamais utilisées
                      <span>échelle mondiale*</span>
                    </div>
                  </div>
                  <div>
                    <div className={"number"}>
                      16<span>milliards</span>
                    </div>
                    <div className={"number-description"}>
                      Coût des applications inutiles ou peu utiles
                      <span>échelle européenne*</span>
                    </div>
                  </div>
                  <hr />
                  <div className={"effect"}>
                    Un gaspillage lié à la sur-numérisation et une augmentation
                    du gras fonctionnel des logiciels et applications (
                    <span>Obésiciel</span>)
                  </div>
                  <div className={"source"}>
                    Source :{" "}
                    <a href={site.siteMetadata.urlAcademieNR}>
                      Academie-nr.org
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </LayoutCentered>
        </div>

        <div className={"bloc-category bloc-content"}>
          <LayoutCentered size={"medium"}>
            <TitleBrushed
              color={"blue"}
              position={"right"}
              name={"Les bénéfices"}
            />
            <div className={"benefices"}>
              <div className={"content"}>
                <ul>
                  <li>
                    Réduire le coût de conception et de fonctionnement du
                    service{" "}
                    <span>
                      Notamment par le biais de déduction fiscale (
                      <a
                        href={
                          "https://www.entreprises.gouv.fr/fr/entrepreneuriat/aides-et-financement/credit-d-impot-innovation"
                        }
                      >
                        CII
                      </a>
                      )
                    </span>
                  </li>
                  <li>
                    Améliorer l'expérience utilisateur et la qualité du service
                    rendu
                  </li>
                  <li>
                    Favoriser l'inclusion sociale
                    <span>Notamment via la démarche d'accessibilité</span>
                  </li>
                  <li>
                    Générer de l’innovation et développer de nouveaux modèles
                    durables
                    <span>= Création de valeur ajoutée</span>
                  </li>
                  <li>
                    Renforcer la mobilisation et la cohésion des salariés de
                    votre entreprise grâce à une démarche porteuse de sens
                  </li>
                  <li>
                    Améliorer la réputation de l’entreprise
                    <span>
                      Notamment par le renforcement de la politique RSE
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </LayoutCentered>
        </div>
      </div>
    </MainLayout>
  );
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        urlGreenIT
        urlAcademieNR
      }
    }
  }
`;
